import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

let rndno = localStorage.getItem("rndno");

// create material
export const addDummyProduct = createAsyncThunk(
  "project/addDummyProduct",
  async (action) => {
    try {
      let data = new FormData();
      data.append("rndno", action.rndno);
      data.append("quotation_id", action.quotation_id);
      data.append("area", action.area);
      data.append("name", action.product_name);
      data.append("type", action.type);
      data.append("qty", action.qty);
      data.append("uom", action.uom);
      data.append("brand", action.brand);
      data.append("style", action.style);
      data.append("rate", action.rate);
      data.append("image", action.image);
      data.append("pdf", action.pdf);

      const res = await api.post("admin/sale/addDummyProduct", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// create material
export const updateDummyProduct = createAsyncThunk(
  "project/updateDummyProduct",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("quotation_id", action.quotation_id);
      data.append("area", action.area);
      data.append("name", action.product_name);
      data.append("type", action.type);
      data.append("qty", action.qty);
      data.append("uom", action.uom);
      data.append("rate", action.rate);
      data.append("image", action.image);
      data.append("pdf", action.pdf);

      const res = await api.post("admin/sale/updateDummyProduct", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete category
export const deleteDummyProduct = createAsyncThunk(
  "dummy/deleteDummyProduct",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/deleteDummyProduct/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// single uom
export const getDummyProduct = createAsyncThunk(
  "category/getDummyProduct",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/getDummyProduct/${action}`);
      return res.data.data;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//------- Material ---------------
// create material
export const addDummyMaterialVariant = createAsyncThunk(
  "project/addDummyMaterialVariant",
  async (action) => {
    try {
      let data = new FormData();
      data.append("rndno", action.rndno);
      data.append("color", action.color);
      data.append("size", action.size);
      data.append("weight", action.weight);
      data.append("sku", action.sku);
      data.append("price", action.price);
      data.append("image", action.image);

      const res = await api.post("admin/sale/addDummyMaterialVariant", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

// create material
export const updateDummyMaterialVariant = createAsyncThunk(
  "project/updateDummyMaterialVariant",
  async (action) => {
    try {
      let data = new FormData();
      data.append("id", action.id);
      data.append("color", action.color);
      data.append("size", action.size);
      data.append("weight", action.weight);
      data.append("sku", action.sku);
      data.append("price", action.price);
      data.append("image", action.image);
      const res = await api.post(
        "admin/sale/updateDummyMaterialVariant",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

//delete category
export const deleteDummyMaterialVariant = createAsyncThunk(
  "dummy/deleteDummyMaterialVariant",
  async (action) => {
    try {
      const res = await api.get(`admin/sale/deleteDummyProduct/${action}`);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.response.data);
    }
  }
);

const initialState = {
  dummyItems: [],
  dummyMaterialVariant: [],
  singleItem: {},
  totalQty: 0,
  totalAmount: 0,
};

const projectDummySlice = createSlice({
  name: "dummy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addDummyProduct.fulfilled, (state, action) => {
        //console.log("done");
        if (action.payload.success) {
          toast.success("Quotation Added Successfully");
          state.dummyItems = [...state.dummyItems, action.payload.message.data];
          state.totalQty = action.payload.message.total_qty;
          state.totalAmount = action.payload.message.total_amount;
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(getDummyProduct.fulfilled, (state, action) => {
        state.singleItem = action.payload;
      })
      .addCase(updateDummyProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Category Updated Successfully");
          state.dummyItems = state.dummyItems.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteDummyProduct.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Category Deleted Successfully");
          console.log(action.payload);
          state.dummyItems = state.dummyItems.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          toast.error(action.payload.message);
        }
      })
      .addCase(addDummyMaterialVariant.fulfilled, (state, action) => {
        //console.log("done");
        if (action.payload.success) {
          toast.success("Material Variant Added Successfully");
          state.dummyMaterialVariant = [
            ...state.dummyMaterialVariant,
            action.payload.message,
          ];
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(updateDummyMaterialVariant.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Material Variant Updated Successfully");
          state.dummyMaterialVariant = state.dummyMaterialVariant.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      })
      .addCase(deleteDummyMaterialVariant.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Material Variant Deleted Successfully");
          console.log(action.payload);
          state.dummyMaterialVariant = state.dummyMaterialVariant.filter(
            (item) => item.id !== parseInt(action.payload.message)
          );
        } else {
          toast.error(action.payload.message);
        }
      });
  },
});

export default projectDummySlice.reducer;
