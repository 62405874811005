import * as React from "react";
import { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  Container,
  Button,
  TextField,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { NavLink, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Chair from "../../assets/images/chair.jpg";
import Cabinet from "../../assets/images/cabinet.jpg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Formik, Form, ErrorMessage } from "formik";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PreviewImage from "../../components/PreviewImage/PreviewImage";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getSaleItemDetail,
  addSaleFinishes,
  getSaleFinishes,
  updateSaleFinishes,
  deleteSaleFinishes,
  updateSaleItemDetail,
} from "./Slice/designSlice";
import { getCategoryList } from "../Settings/Categories/categorySlice";
import {
  getCategoryFinish,
  getSingleFinish,
} from "../Settings/Finish/finishSlice";

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const category = [
  {
    value: "marble",
    label: "marble",
  },
];
const name = [
  {
    value: "marble 1",
    label: "marble 1",
  },
];
const EditDesign = () => {
  const id = useParams();
  const designList = useSelector((state) => state.design);
  const categoryList = useSelector((state) => state.category);
  const finishList = useSelector((state) => state.finish);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSaleItemDetail(id.id));
    dispatch(getCategoryList());
  }, []);

  const [categories, setCategory] = React.useState([]);
  const [finishes, setFinishes] = React.useState([]);
  const [uom, setUom] = React.useState("");
  useEffect(() => {
    setCategory(categoryList.categorylists);
  }, [categoryList.categorylists]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [selectedId,setSelectedId] = React.useState("");

  const getItem = (e) => {
    dispatch(getSaleFinishes(e));
    handleOpenEdit(true);
  }
  console.log(designList);
  const remove = (e) => {
    setSelectedId(e);
    handleOpenDelete(true);
  }

  const handleRemove = () =>{
    dispatch(deleteSaleFinishes(selectedId));
    handleCloseDelete(true);
  }
  const fileRef = useRef(null);

  const handleChangeCategory = (e) => {
    dispatch(getCategoryFinish(e.target.value));
  };
  
  useEffect(() => {
    setFinishes(finishList.categoryFinishesList);
  }, [finishList.categoryFinishesList]);

  const handleChangeFinishes = (e) => {
    dispatch(getSingleFinish(e.target.value));
  };

  useEffect(() => {
    setUom(finishList.singleFinish.uom);
  }, [finishList.singleFinish]);

  const navigate = useNavigate();
  useEffect(() => {
    if (designList.isAdded) {
      setTimeout(
        () => navigate("/design/add-design/" + designList.saleItemDetail.id),
        2500
      );
    }
  }, [designList.isAdded]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          {/* <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/design/edit-design"
                className="tab-header"
              >
                RFD
              </NavLink>
              <NavLink activeclassname="active" to="/#" className="tab-header">
                GFC
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/design/edit-design"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Open
                  </NavLink>
                  <NavLink
                    to="/purchase/approved"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    OFC (Out For Approval)
                  </NavLink>
                </div>
              </Grid>
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/design"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    RFD
                  </NavLink>
                  <NavLink
                    to="/GFC"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    GFC
                  </NavLink>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <h3 className="mb-0">Account Details</h3>
            <p>
              <strong>Beneficiary Name:-</strong> Interiah<br></br>
              <strong>Account Number:-</strong> 1234567895241 <br></br>
              <strong>IFSC:-</strong> SBIN0003564<br></br>
              <strong>Branch:-</strong> Delhi<br></br>
              <strong>Address:-</strong> Kriti Nagar Delhi
            </p>
          </Grid>
          <Grid item xs={6}>
            <div className="float-right">
              <h3 className="mb-0">Project Details</h3>
              <p>
                <strong>Project Name:-</strong>{" "}
                {designList.saleItemDetail.projectId}
                <br></br>
                <strong>Client Name:-</strong>{" "}
                {designList.saleItemDetail.client_first_name}{" "}
                {designList.saleItemDetail.client_last_name} <br></br>
                <strong>Address:-</strong>{" "}
                {designList.saleItemDetail.client_address}
                <br></br>
                {/* <strong>Product:-</strong> Shoe Rack<br></br>
                <strong>Production No.:-</strong> 12345678 */}
              </p>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="order-view p-0 mt-10">
              <h3>
                {designList.saleItemDetail.sale_items &&
                  designList.saleItemDetail.sale_items.area}
              </h3>
              <table>
                <tr>
                  <th>ITEM NAME</th>
                  <th>QTY.</th>
                  <th>DESIGN</th>
                  <th>DRAWING</th>
                </tr>
                <tr>
                  <td>
                    {designList.saleItemDetail.sale_items &&
                      designList.saleItemDetail.sale_items.product_name}
                  </td>
                  <td>
                    {designList.saleItemDetail.sale_items &&
                      designList.saleItemDetail.sale_items.qty}
                  </td>
                  <td>
                    {designList.saleItemDetail.sale_items ? (
                      <>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            designList.saleItemDetail.sale_items.drawing
                          }
                          alt="design"
                          width={50}
                          height={50}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>
                    {designList.saleItemDetail.sale_items ? (
                      <>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            designList.saleItemDetail.sale_items.pdf
                          }
                          alt="design"
                          width={50}
                          height={50}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </table>
            </Grid>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize={true}
          initialValues={{
            comment: designList.saleItemDetail.sale_items
              ? designList.saleItemDetail.sale_items.comment
              : "",
            note: designList.saleItemDetail.sale_items
              ? designList.saleItemDetail.sale_items.note
              : "",
            image: "",
            id: designList.saleItemDetail.sale_items
              ? designList.saleItemDetail.sale_items.id
              : "",
            sale_id: designList.saleItemDetail.id,
          }}
          onSubmit={async (values) => {
            console.log(values);
            dispatch(updateSaleItemDetail(values));
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1} className="border mt-20 p-5 mb-20">
                <Grid item xs={9}>
                  <div className="design-specification mb-10 p-5">
                    <h3 className="m-0 mb-10">Upload Drawing And Design</h3>
                    {/* <p className='m-0'>Upoad</p> */}
                    {/* <TextField
                          name="drawingLabel"
                          // label="Drawing Label Name"
                          type="file"
                          variant="standard"
                          className=" w-100 mb-15"
                          onChange={handleChange}
                      /> */}
                    <div className="d-flex mt-20 mb-10">
                      <input
                        ref={fileRef}
                        className="form-control"
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0]);
                        }}
                        style={{ display: "none" }}
                      />

                      <span
                        className="btn-upload btn mt-3"
                        onClick={() => {
                          fileRef.current.click();
                        }}
                      >
                        <FileUploadIcon />
                      </span>
                      <span className="upload-img">
                        {values.image && <PreviewImage file={values.image} />}
                      </span>
                    </div>
                  </div>
                  <div className="design-specification mb-10 p-5">
                    <h3 className="m-0 mb-10">Other Details</h3>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      name="comment"
                      placeholder="Special Comment"
                      variant="standard"
                      onChange={handleChange}
                      className="w-100 mb-15"
                      value={values.comment}
                    />
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      name="note"
                      placeholder="Note"
                      variant="standard"
                      onChange={handleChange}
                      className="w-100 mb-15"
                      value={values.note}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ paddingRight: "8px", paddingLeft: "13px" }}
                >
                  <div className="design-specification mb-10 p-5">
                    <div className="d-flex justify-content-between">
                      <h3 className="m-0 mb-10">Finish</h3>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={handleOpen}
                      >
                        <AddBoxIcon />
                      </IconButton>
                    </div>
                    <table className="w-100 border-0" style={{borderCollapse:'collapse'}}>
                    {designList.saleItemDetail.finishes &&
                      designList.saleItemDetail.finishes.map((data, index) => (
                      <tr>
                        <td className="border-0">
                          <div>
                            <p>{data.drawing_label}</p>
                            <p>{data.finishes_id} {data.category_id} {data.uom_id}</p>
                          </div>
                        </td>
                        <td className="border-0 text-right">
                            {/* <IconButton
                              color="secondary"
                              size="small"
                              onClick={() => getItem(data.id)}
                            >
                              <ModeEditIcon/>
                            </IconButton> */}
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => remove(data.id)}
                              // onClick={handleOpenDelete}
                            >
                              <DeleteForeverIcon/>
                            </IconButton>
                        </td>
                      </tr>
                      ))}
                    </table>
                    {/* {designList.saleItemDetail.finishes &&
                      designList.saleItemDetail.finishes.map((data, index) => (
                        <>
                          <p>{data.drawing_label}</p>
                          <p>
                            {data.finishes_id} {data.category_id} {data.uom_id}
                          </p>
                        </>
                      ))} */}
                  </div>
                </Grid>
                <Grid item xs={9} className="mb-30 m-0">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    autoFocus
                    className="float-right"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add Finishes
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            initialValues={{
              drawingLabel: "",
              category: "",
              name: "",
              qty :"",
              proc_by:""
            }}
            onSubmit={async (values) => {
              let data = {
                id: designList.saleItemDetail.sale_items
                  ? designList.saleItemDetail.sale_items.id
                  : "",
                sale_id: designList.saleItemDetail.id,
                drawing_label: values.drawingLabel,
                category_id: values.category,
                finishes_id: values.name,
                qty: values.qty,
                proc_by: values.proc_by
              };
              // console.log(data);
              dispatch(addSaleFinishes(data));
              setOpen(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  name="drawingLabel"
                  label="Drawing Label Name"
                  type="text"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  id="category"
                  name="category"
                  select
                  label="Category"
                  defaultValue="marble"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={(event) => {
                    handleChangeCategory(event);
                    handleChange(event);
                  }}
                >
                  {categories.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="name"
                  name="name"
                  select
                  label="Name"
                  defaultValue="marble 1"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={(event) => {
                    handleChangeFinishes(event);
                    handleChange(event);
                  }}
                >
                  {finishes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="uom"
                  label="UOM"
                  type="text"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={uom}
                  InputLabelProps={{
                    shrink: true,
                    readOnly: true,
                  }}
                />
                <TextField
                  name="qty"
                  label="Qty"
                  type="text"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  name="proc_by"
                  label="Proc By"
                  type="text"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Send
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Modal End */}
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          Edit Finishes
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
          enableReinitialize={true}
            initialValues={{
                id: designList.saleFinishes.id,
                drawingLabel: designList.saleFinishes.drawing_label,
                category: designList.saleFinishes.category_id,
                name: designList.saleFinishes.finishes_id,
                uom: designList.saleFinishes.uom_id,
                qty: designList.saleFinishes.qty,
                proc_by:designList.saleFinishes.proc_by,
            }}
            onSubmit={async (values) => {
              console.log(values)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  name="drawingLabel"
                  label="Drawing Label Name"
                  type="text"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.drawingLabel}
                />
                 <TextField
                  id="category"
                  name="category"
                  select
                  label="Category"
                  defaultValue="marble"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={(event) => {
                    handleChangeCategory(event);
                    handleChange(event);
                  }}
                  value={values.category}
                >
                  {categories.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="name"
                  name="name"
                  select
                  label="Name"
                  defaultValue="marble 1"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={(event) => {
                    handleChangeFinishes(event);
                    handleChange(event);
                  }}
                  value={values.name}
                >
                  {finishes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="uom"
                  label="UOM"
                  type="text"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={uom}
                  InputLabelProps={{
                    shrink: true,
                    readOnly: true,
                  }}
                />
                <TextField
                  name="qty"
                  label="Qty"
                  type="text"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.qty}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  name="proc_by"
                  label="Proc By"
                  type="text"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.proc_by}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Send
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
      {/* Delete Modal start */}
      <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete the Finish ?"}
          </DialogTitle>
          <DialogContent style={{ width: "500px" }}>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete}>Cancel</Button>
            <Button
              onClick={handleRemove}
              variant="contained"
              color="error"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Modal End */}
    </>
  );
};

export default EditDesign;
