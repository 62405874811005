import React from "react";
import { Container, Grid, Typography, Card, CardContent } from "@mui/material";
import Header from "../../components/Header/Header";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const Dashboard = () => {
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={3} className="mt-30">
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <CardContent>
                <div class="d-flex justify-content-between">
                  <div className="card-widget">
                    <h3 className="mb-0">Title</h3>
                    <h2 className="mb-0">500</h2>
                  </div>
                  <div className="widget-icon">
                    <h2>
                      <PeopleAltIcon color="error" />
                    </h2>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
