import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chair from "../../assets/images/chair.jpg";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  getProjectQuotation,
  convertToSale,
} from "./Slice/projectQuotationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getUserUsertype } from "../Contacts/Slice/userSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const project = [
  {
    value: "Paint Supplier",
    label: "Paint Supplier",
  },
  {
    value: "Wood Supplier",
    label: "Wood Supplier",
  },
  {
    value: "Furniture Crafting Product",
    label: "Furniture Crafting Product",
  },
];

const usertype = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Production",
    label: "Production",
  },
  {
    value: "Store",
    label: "Store",
  },
  {
    value: "Supervisor",
    label: "Supervisor",
  },
  {
    value: "Designer",
    label: "Designer",
  },
  {
    value: "Management",
    label: "Management",
  },
];
const CreateQuotation = () => {
  const id = useParams();
  const makeQuotationList = useSelector((state) => state.projectQuotation);
  const userList = useSelector((state) => state.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectQuotation(id.id));
    dispatch(getUserUsertype());
  }, []);

  console.log(userList);
  const [users, setUser] = React.useState([]);
  useEffect(() => {
    setUser(userList.userUsertypes);
  }, [userList.userUsertypes]);

  console.log(makeQuotationList);
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const navigate = useNavigate();
  useEffect(() => {
    if (makeQuotationList.isAdded) {
      setTimeout(() => navigate("/quotes/"), 2500);
    }
  }, [makeQuotationList.isAdded]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container className="pl-50 pr-50">
          <Grid item xs={12}>
            <Card className="mt-30 mb-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  <h4>Create Quotation</h4>
                </div>
              </Grid>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: makeQuotationList.quotationDetail.id,
                  project_name: makeQuotationList.quotationDetail.project_name,
                  quotation_id: makeQuotationList.quotationDetail.quotation_id,
                  image: "",
                  user: "",
                  date: "",
                }}
                onSubmit={async (values) => {
                  dispatch(convertToSale(values));
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="quotation_id"
                          label="Quotation Id"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.quotation_id}
                        />
                        {/* <TextField
                          id="quotation"
                          name="quotation"
                          select
                          label="Select Quotation"
                          defaultValue="Furniture Crafting Product"
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        >
                          {project.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField> */}
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="project_name"
                          label="Project Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                          value={values.project_name}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <Button
                          href={
                            process.env.REACT_APP_IMAGE_URL +
                            "/" +
                            makeQuotationList.quotationDetail.upload
                          }
                          variant="text"
                          target="_blank"
                          style={{ fontWeight: "600" }}
                        >
                          Presentation Link
                        </Button>
                      </Grid>
                      {makeQuotationList.quotationDetail.area_wise &&
                        makeQuotationList.quotationDetail.area_wise.map(
                          (data, index) => (
                            <>
                              <Grid item xs={8} className="form-custom">
                                <TextField
                                  label=" Area"
                                  variant="standard"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  className="w-100"
                                  onChange={handleChange}
                                  value={data.no + ".  " + data.area}
                                />
                              </Grid>
                              <Grid item xs={12} className="mt-20 mb-20">
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Product Name</TableCell>
                                        <TableCell>Product Type</TableCell>
                                        <TableCell>UOM</TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Rate</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Drawing</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {data.products &&
                                        data.products.map((item, index1) => (
                                          <TableRow key={index1}>
                                            <TableCell>{item.sno}</TableCell>
                                            <TableCell>
                                              {item.product_name}
                                            </TableCell>
                                            <TableCell>
                                              {item.product_type}
                                            </TableCell>
                                            <TableCell>{item.uom}</TableCell>
                                            <TableCell>{item.qty}</TableCell>
                                            <TableCell>{item.rate}</TableCell>
                                            <TableCell>{item.amount}</TableCell>
                                            <TableCell>
                                              <img
                                                src={
                                                  process.env
                                                    .REACT_APP_IMAGE_URL +
                                                  "/" +
                                                  item.drawing
                                                }
                                                alt="product-image"
                                                width="60"
                                              />
                                            </TableCell>
                                            <TableCell>
                                              {item.pdf ? (
                                                <>
                                                  <Button
                                                    href={
                                                      process.env
                                                        .REACT_APP_IMAGE_URL +
                                                      "/" +
                                                      item.pdf
                                                    }
                                                    variant="text"
                                                    target="_blank"
                                                    style={{
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    View
                                                  </Button>
                                                </>
                                              ) : null}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </>
                          )
                        )}

                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="date"
                          label="Payment Date"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="date"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="user"
                          name="user"
                          select
                          label="Select Designer Type"
                          defaultValue="m"
                          variant="standard"
                          className="w-100 mb-15"
                          onChange={handleChange}
                        >
                          {users.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <p style={{ marginTop: "0", fontSize: "13" }}>
                          Upload Payment Receipt
                        </p>
                        <TextField
                          className="w-100 mb-15"
                          variant="standard"
                          type="file"
                          name="upload"
                          onChange={(event) => {
                            setFieldValue("image", event.target.files[0]);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Convert To Sale
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
      {/* Add supplier Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Add
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            initialValues={{
              supplierName: "",
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              // alert(JSON.stringify(values, null, 2));
              console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="size"
                          variant="standard"
                          placeholder="Size"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                        <TextField
                          id="weight"
                          variant="standard"
                          placeholder="Weight"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                        <div style={{ display: "flex" }}>
                          <input
                            accept="image/*"
                            type="file"
                            id="select-image"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              setSelectedImage(e.target.files[0])
                            }
                            // onChange={handleChange}
                          />
                          <label htmlFor="select-image">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              <FileUploadOutlinedIcon />
                            </Button>
                          </label>
                          {imageUrl && selectedImage && (
                            <img
                              src={imageUrl}
                              alt={selectedImage.name}
                              height="40px"
                              style={{ marginLeft: "20px" }}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add supplier Modal End */}
    </>
  );
};

export default CreateQuotation;
