import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import Grid from "@mui/material/Grid";
import {
  Container,
  Button,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import { ICellRendererParams } from "ag-grid-community";
import Header from "../../components/Header/Header";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SquareIcon from "@mui/icons-material/Square";
import ListSubheader from "@mui/material/ListSubheader";
import { useNavigate, Link } from "react-router-dom";
import { LicenseManager } from "ag-grid-enterprise";
import { useDispatch, useSelector } from "react-redux";
import {
  getDesign,
  designMailCustomer,
  sentCustomerApprovalEmail,
} from "./Slice/designSlice";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Formik, Form, ErrorMessage } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

LicenseManager.setLicenseKey("your license key");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Designs = () => {
  const designList = useSelector((state) => state.design);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDesign());
  }, []);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(designList.designs);
  }, [designList.designs]);

  const navigate = useNavigate();
  const viewSale = (e) => {
    navigate("/sales/sales-order-view/" + e);
  };

  const handleClickApproval = (e) => {
    dispatch(designMailCustomer(e));
    setOpenAdd(true);
  };

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  let d = new Date();
  const params = {
    columnKeys: [
      "ProductId",
      "NoOfItem",
      "AssignTo",
      "Qty",
      "Type",
      "Status",
      "Completed",
      "Action",
    ],
    allColumns: false,
    fileName: `Sale Order ${d.toLocaleDateString()}`,
    sheetName: "Sale Order",
  };
  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  //add design
  const editHandle = (e) => {
    navigate("/design/add-design/" + e);
  };

  //print or view the booklet
  const handleClickView = (e) => {
    navigate("/booklet/print-booklet/" + e);
  };

  //   const [rowData] = useState([
  //     {
  //       productId: "1",
  //       noOfItem: "SO-2",
  //       assignTo: "Rob Decor",
  //       qty: 35000.0,
  //       type: "2023-02-10",
  //       status: "Expected",
  //       completed: "2023-02-14",
  //       action: "In Stock",
  //     },
  //   ]);

  const [status, setStatus] = React.useState("");

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const [columnDefs] = useState([
    {
      field: "project_id",
      headerName: "Product Id",
      sortable: true,
      rowDrag: true,
      filter: false,
      cellStyle: { borderRightColor: "#e2e2e2" },
      maxWidth: 150,
      cellStyle: {
        // you can use either came case or dashes, the grid converts to whats needed
        fontWeight: 600,
      },
    },
    {
      field: "no_of_item",
      headerName: "No of Item",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "assign_to",
      headerName: "Assign To",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "total_qty",
      headerName: "Qty",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "sale_type",
      headerName: "Type",
      sortable: true,
      filter: "agDateColumnFilter",
      cellStyle: {
        // you can use either came case or dashes, the grid converts to whats needed
        color: "#e42c00", // light green
      },
    },
    {
      field: "type",
      headerName: "Status",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "completed_qty",
      headerName: "Completed",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <Tooltip title="Add Drawing" arrow placement="top">
              <IconButton
                aria-label="Add Drawing"
                color="primary"
                onClick={() => editHandle(params.data.id)}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send For Approval" arrow placement="top">
              <IconButton
                aria-label="Send For Approval"
                color="success"
                onClick={() => handleClickApproval(params.data.id)}
              >
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Booklet" arrow placement="top">
              <IconButton
                aria-label="add"
                color="warning"
                onClick={() => handleClickView(params.data.id)}
                // onClick={handleOpenAdd}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      maxWidth: 150,
    },
  ]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
      //   minWidth: 150,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/design"
                className="tab-header"
              >
                RFD
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/GFC"
                className="tab-header"
              >
                GFC
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/design"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Open
                  </NavLink>
                  <NavLink
                    to="/OFA"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    OFA (Out For Approval)
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  {/* <small>Shipping From:</small> */}
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <div className="action-buttons">
              <IconButton color="primary" onClick={onBtExport}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                // rowSelection={'single'}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Send To Customer
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              sale_id: designList.saleMailContent
                ? designList.saleMailContent.sale_id
                : "",
              email: "",
            }}
            onSubmit={async (values) => {
              dispatch(sentCustomerApprovalEmail(values));
              setOpenAdd(false);
              console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                <Typography>
                  <p style={{ fontWeight: "500" }}>
                    Dear {designList.saleMailContent.customer_name},
                  </p>
                  <p>{designList.saleMailContent.description}</p>
                  <p>
                    Booklet Link{" "}
                    <Link to="/booklet/print-booklet">
                      {" "}
                      {designList.saleMailContent.booklet_line}
                    </Link>
                  </p>
                  <p>{designList.saleMailContent.approval_line}</p>

                  <p className="m-0" style={{ fontWeight: "500" }}>
                    {designList.saleMailContent.greet}
                  </p>
                  <p className="mt-0">
                    {designList.saleMailContent.company_name}
                    <br></br>
                    {designList.saleMailContent.email}
                    <br></br>
                    {designList.saleMailContent.phone}
                  </p>
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Send
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Modal End */}
    </>
  );
};

export default Designs;
