import React from "react";
import { Button, Tab } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import StoreIcon from "@mui/icons-material/Store";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import WidgetsIcon from "@mui/icons-material/Widgets";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import HeaderRight from "./HeaderRight";
import { useLocation } from "react-router-dom";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ReportIcon from "@mui/icons-material/Report";

export default function Header() {
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/dashboard");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className="header-wrapper">
        <Button
          size="large"
          className="btn-theme"
          onClick={() => handleRedirect()}
        >
          Interiah
        </Button>
        <NavLink
          exact={true}
          to="/sales"
          className={
            ["/sales", "/salesorder", "/quote", "/quotes"].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<StoreIcon />} label="SELL" />
        </NavLink>
        <NavLink
          exact={true}
          to="/design"
          className={
            ["/designs", "/OFA", "/GFC"].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<ArchitectureIcon />} label="DESIGN" />
        </NavLink>
        {/* <NavLink
          exact={true}
          to="/manufacturingorders"
          className={
            [
              "/manufacturingorders",
              "/manufacturingorder/done",
              "/manufacturingorder",
              "/manufacturingorders",
              "/manufacturingorder/stockOut",
              "/manufacturingorder/inprogress",
              "/makeorder",
              "/manufacturingorder/cancel",
              "/add-makeorder",
              "/edit-makeorder",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<ThumbsUpDownIcon />} label="MAKE" />
        </NavLink> */}
        <NavLink
          exact={true}
          to="/purchases"
          className={
            [
              "/purchase",
              "/purchase/done",
              "/purchase/approved",
              "/purchase/DisApproved",
              "/purchaseorder",
              "/purchase-outsource",
              "/outsource-purchases",
              "/outsource-purchase/done",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<ShoppingBasketIcon />} label="BUY" />
        </NavLink>
        {/* <NavLink  activeclassname="active" to="/#" className='menu-item'><Tab icon={<WidgetsIcon/>} label="STOCK" /></NavLink>   */}
        <NavLink
          exact={true}
          to="/Products"
          className={
            ["/Products", "/materials", "/add-material"].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<LocalActivityIcon />} label="ITEMS" />
        </NavLink>
        <NavLink
          exact={true}
          to="/contacts/customers"
          className={
            [
              "/contacts/customers",
              "/contacts/suppliers",
              "/add-customer",
              "/contacts/users",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<GroupIcon />} label="CONTACTS" />
        </NavLink>
        <NavLink
          exact={true}
          to="/settings/uoms"
          className={
            [
              "/settings/uoms",
              "/settings/taxRates",
              "/settings/categories",
              "/settings/operations",
              "/settings/department",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<SettingsIcon />} label="SETTINGS" />
        </NavLink>
        <NavLink
          exact={true}
          to="/billing"
          className={
            ["/billing", "/create-bill", "/BillCancel", "/challan"].includes(
              pathname
            )
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<NoteAddIcon />} label="BILLING" />
        </NavLink>
        {/* <NavLink
          exact={true}
          to="/report/production-report"
          className={
            [
              "/report/production-report",
              "/report/material-history",
              "/report/raw-material",
            ].includes(pathname)
              ? "menu-item active"
              : "menu-item"
          }
        >
          <Tab icon={<ReportIcon />} label="Report" />
        </NavLink> */}
        <div className="header-sticky-button">
          <IconButton
            color="secondary"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <AddIcon />
          </IconButton>
        </div>
        <div className="header-right">
          <HeaderRight />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 95, left: 920 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="mt-10 mb-10">
          {/* <Link to="/quote" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Quote
            </MenuItem>
          </Link>
          <Link to="/salesorder" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Sales Order
            </MenuItem>
          </Link>

          <Link to="/makeorder" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Make Order
            </MenuItem>
          </Link> */}
          {/* <Link to="/manufacturingorder" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Manufacturing Order
            </MenuItem>
          </Link> */}
          <Link to="/purchaseorder" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Purchase Order
            </MenuItem>
          </Link>
          {/*<Link to="/purchase-outsource" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Outsourced Purchase Order
            </MenuItem>
          </Link>
           <Link to="/#" className='header-nav'>
            <MenuItem><span className='icon'><AddIcon/></span> Stock Adjustment</MenuItem>
          </Link> 
          <Link to="/#" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              New Product Card
            </MenuItem>
          </Link>*/}
          <Link to="/add-material" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              New Material Card
            </MenuItem>
          </Link>
          <Link to="/add-customer" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              Customer
            </MenuItem>
          </Link>
          {/* <Link to="/project-add" className="header-nav">
            <MenuItem>
              <span className="icon">
                <AddIcon />
              </span>{" "}
              New Project
            </MenuItem>
          </Link> */}
        </div>
      </Popover>
    </>
  );
}
