import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import Grid from "@mui/material/Grid";
import { Container, Card, TextField, Button } from "@mui/material";
import { Formik, Form, ErrorMessage,Field, FieldArray, } from "formik";
import PhoneIcon from "@mui/icons-material/Phone";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../CommonSlice/addressSlice";
import { addCustomer } from "./Slice/customerSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "800px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const customerSchema = Yup.object().shape({
  customer_firstname: Yup.string().required("Name is Required"),
  customer_phone: Yup.string().required("Phone Number is Required"),
});

const account_types = [
  {
    value: "Search Account Type",
    label: "Search Account Type",
  },
  {
    value: "Walk-in",
    label: "Walk-in",
  },
  {
    value: "Designer/Architect",
    label: "Designer/Architect",
  },
  {
    value: "Partner/Retail/Co",
    label: "Partner/Retail/Co",
  },
  {
    value: "OEM",
    label: "OEM",
  },
  {
    value: "Corporate",
    label: "Corporate",
  },
];

const AddCustomer = () => {
  const addressList = useSelector((state) => state.address);
  const customerList = useSelector((state) => state.customer);
  // console.log(addressList);
  // console.log(customerList);
  // console.log(addressList.address_type);
  const [isChecked, setIsChecked] = React.useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openAdd2, setOpenAdd2] = React.useState(false);
  const handleOpenAdd2 = () => setOpenAdd2(true);
  const handleCloseAdd2 = () => setOpenAdd2(false);

  const [billingAddress, setBillingAddress] = React.useState("");
  const [shippingAddress, setShippingAddress] = React.useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (addressList.address_type === "Billing") {
      console.log(addressList.address_type);
      setBillingAddress(addressList.address);
    } else if (addressList.address_type === "Shipping") {
      console.log(addressList.address_type);
      setShippingAddress(addressList.address);
    }
  }, [addressList]);

  const navigate = useNavigate();
  useEffect(() => {
    if (customerList.isAdded) {
      setBillingAddress(" ");
      setShippingAddress(" ");
      setTimeout(
        () => navigate("/add-project/" + customerList.customerId),
        2500
      );
    }
  }, [customerList.isAdded]);

  const handleAddress = (e) => {
    if (e.target.checked == true) {
      localStorage.setItem("shipping_id", "");
      setShippingAddress(billingAddress);
    }
  };
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container className="pl-50 pr-50">
          <Grid item xs={12}>
            <Card className="mt-50" style={{ backgroundColor: "#fff" }}>
              <Grid item xs={12}>
                <div className="card-header">
                  {/* <p>Customer</p> */}
                  <h4>Customer</h4>
                </div>
              </Grid>
              <Formik
                validationSchema={customerSchema}
                enableReinitialize={true}
                initialValues={{
                  customer_firstname: "",
                  customer_lastname: "",
                  customer_companyname: "",
                  customer_email: "",
                  customer_phone: "",
                  comment: "",
                  billingAddress: "",
                  shippingAddress: "",
                  customer_gst: "",
                  manager_whatsapp: "",
                  manager_name: "",
                  manager_email: "",
                  account_type: "",
                  logo:"",
                  prefix:"",
                  variant: [
                    {
                      manager_name: "",
                      manager_email: "",
                      manager_whatsapp: "",
                      manager_account_type: "",
                      manager_comment: "",
                    },
                  ],
                }}
                onSubmit={async (values) => {
                  let data = {
                    customer_firstname: values.customer_firstname,
                    customer_lastname: values.customer_lastname,
                    customer_companyname: values.customer_companyname,
                    customer_email: values.customer_email,
                    customer_phone: values.customer_phone,
                    customer_gst: values.customer_gst,
                    // manager_whatsapp: values.manager_whatsapp,
                    // manager_name: values.manager_name,
                    // manager_email: values.manager_email,
                    // comment: values.comment,
                    billingAddress: billingAddress,
                    shippingAddress: shippingAddress,
                    billing_id: localStorage.getItem("billing_id"),
                    shipping_id: localStorage.getItem("shipping_id"),
                    // account_type: values.account_type,
                    logo: values.logo,
                    term_condition: value,
                    prefix:values.prefix,
                    variant: values.variant
                  };
                  console.log(data);
                  dispatch(addCustomer(data));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid container className="card-body">
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_firstname"
                              label="First Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component="div"
                              name="customer_firstname"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_lastname"
                              label="Last Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_email"
                              label="Email"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              type="email"
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_phone"
                              label="Phone"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              type="text"
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              component="div"
                              name="customer_phone"
                              className="error-message"
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_companyname"
                              label="Firm Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="customer_gst"
                              label="GST No"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                        
                          {/* <Grid item xs={12} className="form-custom">
                            <label>Add Project Manager</label>
                          </Grid> */}
                          {/* <Grid item xs={6} className="form-custom">
                            <TextField
                              id="manager_name"
                              label="Name"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="manager_email"
                              label="Email"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="manager_whatsapp"
                              label="Whatsapp No"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} className="form-custom">
                            <TextField
                              id="account_type"
                              name="account_type"
                              select
                              label="Account Type"
                              defaultValue="Search Account Type"
                              variant="standard"
                              className="w-100"
                              onChange={handleChange}
                            >
                              {account_types.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} className="form-custom">
                            <TextField
                              id="comment"
                              label="Comment"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                          </Grid> */}
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid Container>
                          <Grid item xs={12} className="form-custom">
                            <TextField
                              id="billingAddress"
                              label="Billing Address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocationOnRoundedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Enter Address"
                              type="text"
                              variant="standard"
                              className="w-100"
                              onClick={handleOpenAdd}
                              onChange={setBillingAddress}
                              value={billingAddress}
                            />
                          </Grid>
                          <Grid item xs={12} className="form-custom">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Shipping Address Same As Billing Address"
                                onChange={(e) => setIsChecked(e.target.checked)}
                                onClick={handleAddress}
                              />
                            </FormGroup>
                          </Grid>
                          {isChecked ? null : (
                            <Grid item xs={12} className="form-custom">
                              <TextField
                                id="shippingAddress"
                                label="Default Shipping Address"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LocationOnRoundedIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="Same as billing address"
                                type="text"
                                variant="standard"
                                className="w-100"
                                onClick={handleOpenAdd2}
                                onChange={setShippingAddress}
                                value={shippingAddress}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className='form-custom'>
                        {/* <p style={{marginTop:'0', fontSize:'13'}}>Add Prefix</p> */}
                        <TextField
                            label="Add Prefix"
                              id="prefix"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              className="w-100"
                              onChange={handleChange}
                            />
                      </Grid>
                      <Grid item xs={6} className='form-custom'></Grid>
                      <Grid item xs={6} className='form-custom'>
                        <p style={{marginTop:'0', fontSize:'13'}}>Upload Client Logo</p>
                        <TextField
                            className="w-100 mb-15"
                            variant="standard"
                            type="file"
                            name="logo"
                            onChange={(event) => {
                            setFieldValue('logo', event.target.files[0]);
                            }}
                        />
                      </Grid>
                      <Grid item xs={12} className="mt-20 mb-20 form-custom">
                      <p style={{marginTop:'0', fontSize:'13'}}>Add Project Manager</p>
                        {/* <h3>
                          <strong>Product Detail</strong>
                        </h3> */}
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                {/* <TableCell>S.NO.</TableCell> */}
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>WhatsApp</TableCell>
                                <TableCell>Account Type</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell>#</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                             
                              <FieldArray name="variant">
                                {({ insert, remove, push }) => (
                                  <div style={{ display: "contents" }}>
                                    {values.variant.length > 0 &&
                                      values.variant.map((friend, index) => (
                                        <TableRow>
                                          {/* <TableCell>{index + 1}</TableCell> */}
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.manager_name`}
                                              placeholder="Manager Name"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.manager_email`}
                                              placeholder="Email"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.manager_whatsapp`}
                                              placeholder="WhatsApp"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                          <TextField
                                              name={`variant.${index}.manager_account_type`}
                                              select
                                              defaultValue="Search Account Type"
                                              variant="standard"
                                              className="w-100 default-input"
                                              onChange={handleChange}
                                            >
                                              {account_types.map((option) => (
                                                <MenuItem
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </TextField>
                                            {/* <Field
                                              name={`variant.${index}.manager_account_type`}
                                              placeholder="Manager Account Type"
                                              className="w-100 default-input"
                                            /> */}
                                          </TableCell>
                                          <TableCell>
                                            <Field
                                              name={`variant.${index}.manager_comment`}
                                              placeholder="Manager Comment"
                                              className="w-100 default-input"
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {values.variant.length > 1 ? (
                                              <IconButton
                                                variant="contained"
                                                onClick={() => remove(index)}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            ) : null}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    <Grid>
                                      <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          push({
                                            product: "",
                                            comment: "",
                                          })
                                        }
                                      >
                                        <DifferenceOutlinedIcon />
                                      </IconButton>
                                    </Grid>
                                  </div>
                                )}
                              </FieldArray>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} className="mt-10 mb-20 form-custom">
                        <p className='m-0 mb-10'>Terms & Conditions:</p>
                        <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={setValue}
                            placeholder="Add Terms & Conditions"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "right", marginBottom: "20px" }}
                      >
                        {/* <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit and Make Project
                        </Button> */}
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          className="ml-10"
                        >
                          Submit and Make Project
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Billing Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
        maxWidth={"lg"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Billing Address
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              firstname: "",
              // lastname: "",
              // companyname: "",
              phone: "",
              address1: "",
              address2: "",
              city: "",
              state_id: "",
              pincode: "",
              country: "",
              address_type: "Billing",
            }}
            onSubmit={async (values) => {
              dispatch(addAddress(values));
              handleCloseAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="firstname"
                          label="Name"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="phone"
                          label="Phone"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="text"
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="address1"
                          label="Address line 1"
                          type="text"
                          placeholder="Enter Street Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} className="form-custom">
                        <TextField
                          id="address2"
                          label="Address line 2"
                          type="text"
                          placeholder="Address line 2 (e.g., Apartment, Unit, Suite or Building)"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="city"
                          label="City/Town"
                          type="text"
                          placeholder="City, District, Town or Village"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="state_id"
                          label="State/Region"
                          type="text"
                          placeholder="State, Province, Region"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="pincode"
                          label="Zip/Postal Code"
                          type="text"
                          placeholder="Zip or Postal Code"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} className="form-custom">
                        <TextField
                          id="country"
                          label="Country"
                          type="text"
                          placeholder="Country"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="standard"
                          className="w-100"
                          onChange={handleChange}
                          value="INDIA"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Billing Modal End */}
      {/* Shipping Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd2}
        aria-labelledby="customized-dialog-title"
        open={openAdd2}
        maxWidth={"lg"}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd2}
        >
          Shipping Address
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              firstname: "",
              // lastname: "",
              // companyname: "",
              phone: "",
              address1: "",
              address2: "",
              city: "",
              state_id: "",
              pincode: "",
              country: "",
              address_type: "Shipping",
            }}
            onSubmit={async (values) => {
              dispatch(addAddress(values));
              handleCloseAdd2(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="firstname"
                      label="Name"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="phone"
                      label="Phone"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                      type="number"
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="address1"
                      label="Address line 1"
                      type="text"
                      placeholder="Enter Street Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} className="form-custom">
                    <TextField
                      id="address2"
                      label="Address line 2"
                      type="text"
                      placeholder="Address line 2 (e.g., Apartment, Unit, Suite or Building)"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="city"
                      label="City/Town"
                      type="text"
                      placeholder="City, District, Town or Village"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="state_id"
                      label="State/Region"
                      type="text"
                      placeholder="State, Province, Region"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="pincode"
                      label="Zip/Postal Code"
                      type="text"
                      placeholder="Zip or Postal Code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="form-custom">
                    <TextField
                      id="country"
                      label="Country"
                      type="text"
                      placeholder="Country"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      className="w-100"
                      onChange={handleChange}
                      value="INDIA"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button onClick={handleCloseAdd2}>cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Shipping Modal End */}
    </>
  );
};

export default AddCustomer;
