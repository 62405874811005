import React, { useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, TextField, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Chair from "../../assets/images/chair.jpg";
import Cabinet from "../../assets/images/cabinet.jpg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Formik, Form, ErrorMessage } from "formik";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProjectSale } from "./Slice/designSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddDesign = () => {
  const id = useParams();
  const designList = useSelector((state) => state.design);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjectSale(id.id));
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const navigate = useNavigate();
  const editDrawing = (e) => {
    navigate("/design/edit-design/" + e);
  };
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          {/* <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/design/add-design"
                className="tab-header"
              >
                RFD
              </NavLink>
              <NavLink activeclassname="active" to="/#" className="tab-header">
                GFC
              </NavLink>
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/design"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    RFD
                  </NavLink>
                  <NavLink
                    to="/GFC"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    GFC
                  </NavLink>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <h3 className="mb-0">Account Details</h3>
            <p>
              <strong>Beneficiary Name:-</strong> Interiah<br></br>
              <strong>Account Number:-</strong> 1234567895241 <br></br>
              <strong>IFSC:-</strong> SBIN0003564<br></br>
              <strong>Branch:-</strong> Delhi<br></br>
              <strong>Address:-</strong> Kriti Nagar Delhi
            </p>
          </Grid>
          <Grid item xs={6}>
            <div className="float-right">
              <h3 className="mb-0">Project Details</h3>
              <p>
                <strong>Project Name:-</strong>{" "}
                {designList.saleDetail.projectId}
                <br></br>
                <strong>Client Name:-</strong>{" "}
                {designList.saleDetail.client_first_name}{" "}
                {designList.saleDetail.client_last_name} <br></br>
                <strong>Address:-</strong>{" "}
                {designList.saleDetail.client_address}
                <br></br>
                {/* <strong>Product:-</strong> Shoe Rack<br></br>
                <strong>Production No.:-</strong> 12345678 */}
              </p>
            </div>
          </Grid>
          <Grid container>
            {designList.saleDetail.area_wise &&
              designList.saleDetail.area_wise.map((data, index) => (
                <>
                  <Grid
                    item
                    xs={12}
                    className="order-view p-0 mt-10"
                    key={index}
                  >
                    <h3>{data.area}</h3>
                    <table>
                      <tr>
                        <th>ITEM NAME</th>
                        <th>QTY.</th>
                        <th>DESIGN</th>
                        <th>DRAWING</th>
                        <th>DESIGNER DRAWING</th>
                      </tr>
                      {data.products &&
                        data.products.map((item, index1) => (
                          <>
                            <tr>
                              <td>{item.product_name}</td>
                              <td>{item.qty}</td>
                              <td>
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_URL +
                                    "/" +
                                    item.drawing
                                  }
                                  alt="design"
                                  width={50}
                                  height={50}
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_URL +
                                    "/" +
                                    item.pdf
                                  }
                                  alt="design"
                                  width={50}
                                  height={50}
                                />
                              </td>
                              <td>
                                {item.designer_drawing != null ? (
                                  <>
                                    <Button>
                                      <IconButton
                                        aria-label="edit"
                                        color="primary"
                                        onClick={() => editDrawing(item.id)}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button>
                                      <IconButton
                                        aria-label="edit"
                                        color="warning"
                                        onClick={() => editDrawing(item.id)}
                                      >
                                        <FileUploadIcon />
                                      </IconButton>
                                    </Button>
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                    </table>
                  </Grid>
                </>
              ))}
          </Grid>
        </Grid>
      </Container>
      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Send To Customer
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={async (values) => {
              console.log(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                />
                <Typography>
                  <p style={{ fontWeight: "500" }}>Dear "Customer Name"</p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                  <p>
                    Booklet Link{" "}
                    <Link to="/booklet/print-booklet">Link of the Booklet</Link>
                  </p>
                  <p>Click the above link to view and approve the design.</p>

                  <p className="m-0" style={{ fontWeight: "500" }}>
                    Thanks
                  </p>
                  <p className="mt-0">
                    Design Team Interiah!<br></br>support@interia-ah.com
                    <br></br>98765432154
                  </p>
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Send
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Modal End */}
    </>
  );
};

export default AddDesign;
