import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { Container, Button, TextField, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Header from "../../components/Header/Header";
import { NavLink, Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import { getState } from "../CommonSlice/stateSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../Settings/Categories/categorySlice";
import {
  addSupplier,
  getSupplier,
  getSingleSupplier,
  deleteSupplier,
  updateSupplier,
} from "./Slice/supplierSlice";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const supplierSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
});

const Suppliers = () => {
  let d = new Date();
  const params = {
    columnKeys: ["name", "phone", "category_id", "address"],
    allColumns: false,
    fileName: `Suppliers ${d.toLocaleDateString()}`,
    sheetName: "Suppliers",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const supplierList = useSelector((state) => state.supplier);
  const categoryList = useSelector((state) => state.category);
  const stateList = useSelector((state) => state.state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getState());
    dispatch(getSupplier());
  }, []);

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = (e) => {
    setOpenEdit(true);
    dispatch(getSingleSupplier(e));
  };

  const handleCloseEdit = () => setOpenEdit(false);

  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setDeleteId(e);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [states, setState] = React.useState();
  const [categories, setCategory] = React.useState();
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    setState(stateList.states.state);
    setCategory(categoryList.categorylists);
  }, [stateList.states]);

  const handleRemove = () => {
    dispatch(deleteSupplier(deleteId));
    setOpenDelete(false);
  };

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(supplierList.suppliers);
  }, [supplierList.suppliers]);

  const [columnDefs] = useState([
    // {
    //   field: "",
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   filter: false,
    //   maxWidth: 50,
    // },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "category_id",
      headerName: "Category",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "address",
      headerName: "Address",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => handleOpenEdit(params.data.id)}
            >
              <CreateIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => handleClickOpenDelete(params.data.id)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
      maxWidth: 120,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={6}>
                <div className="nav-bar">
                  <NavLink
                    to="/contacts/users"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Users
                  </NavLink>
                  <NavLink
                    to="/contacts/customers"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Customers
                  </NavLink>
                  <NavLink
                    to="/contacts/suppliers"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Suppliers
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <Button variant="text" onClick={handleOpenAdd}>
                    ADD SUPPLIERS
                  </Button>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton color="primary" onClick={onBtExport} size="small">
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Add Supplier
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={supplierSchema}
            initialValues={{
              name: "",
              email: "",
              phone: "",
              category_id: "",
              address: "",
              state_id: "",
              gst_no: "",
            }}
            onSubmit={async (values) => {
              dispatch(addSupplier(values));
              setOpenAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="name"
                  label="Name"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  className="error-message"
                />
                <TextField
                  id="email"
                  label="E-mail Address"
                  variant="standard"
                  type="email"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  id="phone"
                  label="Phone"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  id="category_id"
                  name="category_id"
                  label="Category Id"
                  select
                  variant="standard"
                  defaultValue=""
                  className="w-100 mb-15"
                  onChange={handleChange}
                >
                  {categories.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="address"
                  label="Address"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <TextField
                  id="state_id"
                  name="state_id"
                  label="State"
                  select
                  defaultValue="10"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                >
                  {states.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="gst_no"
                  label="GST No"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Add Supplier
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Modal End */}
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          Edit Supplier
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={supplierSchema}
            enableReinitialize={true}
            initialValues={{
              id: supplierList.singleSupplier.data
                ? supplierList.singleSupplier.data.id
                : "",
              name: supplierList.singleSupplier.data
                ? supplierList.singleSupplier.data.name
                : "",
              email: supplierList.singleSupplier.data
                ? supplierList.singleSupplier.data.email
                : "",
              phone: supplierList.singleSupplier.data
                ? supplierList.singleSupplier.data.phone
                : "",
              category_id: supplierList.singleSupplier.data
                ? supplierList.singleSupplier.data.category_id
                : "",
              address: supplierList.singleSupplier.data
                ? supplierList.singleSupplier.data.address
                : "",
              state_id: supplierList.singleSupplier.data
                ? supplierList.singleSupplier.data.state_id
                : "",
              gst_no: supplierList.singleSupplier.data
                ? supplierList.singleSupplier.data.gst_no
                : "",
            }}
            onSubmit={async (values) => {
              dispatch(updateSupplier(values));
              setOpenEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="name"
                  label="Name"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.name}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  className="error-message"
                />
                <TextField
                  id="email"
                  label="E-mail Address"
                  variant="standard"
                  type="email"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.email}
                />
                <TextField
                  id="phone"
                  label="Phone"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.phone}
                />
                <TextField
                  id="category_id"
                  name="category_id"
                  label="Category Id"
                  select
                  variant="standard"
                  value={values.category_id}
                  className="w-100 mb-15"
                  onChange={handleChange}
                >
                  {categories.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="address"
                  label="Address"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.address}
                />
                <TextField
                  id="state_id"
                  name="state_id"
                  label="State"
                  select
                  variant="standard"
                  className="w-100"
                  onChange={handleChange}
                  value={values.state_id}
                >
                  {states.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="gst_no"
                  label="GST No"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.gst_no}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Update Supplier
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete the supplier ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default Suppliers;
