import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Grid from "@mui/material/Grid";
import { Container, Button, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import DeliveryStatus from "./DeliveryStatus";
import "ag-grid-enterprise";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { quotationDone, cancelQuotation } from "./Slice/quoteSlice";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const QuotesDone = () => {
  let d = new Date();
  const params = {
    columnKeys: [
      "order",
      "createdDate",
      "customer",
      "totalAmount",
      "deliveryDeadline",
      "salesItems",
      "salesDate",
      "ingredients",
      "production",
      "delivery",
    ],
    allColumns: false,
    fileName: `Quotation ${d.toLocaleDateString()}`,
    sheetName: "Quotation",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const quoteList = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(quotationDone());
  }, []);

  console.log(quoteList);
  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(quoteList.quotationDone);
  }, [quoteList.quotationDone]);

  const navigate = useNavigate();
  const editHandle = (e) => {
    navigate("/edit-quote/" + e);
  };
  const viewQuote = (e) => {
    // navigate("/quote/quotation-view/" + e);
    navigate("/project/view-quotation/" + e);
  };
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setDeleteId(e);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleRemove = () => {
    dispatch(cancelQuotation(deleteId));
    setOpenDelete(false);
  };

  const convertQuotation = (e) => {
    navigate("/project/create-quotation/" + e);
  };
  // const [rowData] = useState([
  //   {
  //     createdDate: "25-03-2023",
  //     order: "SO-2",
  //     customer: "Rob Decor",
  //     totalAmount: 35000.0,
  //     deliveryDeadline: "2023-02-10",
  //     status: "Not Shipped",
  //   },
  // ]);

  const [columnDefs] = useState([
    {
      field: "order",
      headerName: "Quotation No",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        return (
          <a href="" rel="noopener" onClick={() => viewQuote(params.data.id)}>
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    // {
    //   field: "customer",
    //   headerName: "Customer",
    //   sortable: true,
    //   filter: "agTextColumnFilter",
    // },
    // {
    //   field: "project",
    //   headerName: "Project Name",
    //   sortable: true,
    //   filter: "agNumberColumnFilter",
    // },
    {
      field: "project_id",
      headerName: "Project Id",
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "link",
      headerName: "Correspondance Link",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            {params.data.link_extension == "pdf" ? (
              <>
                <Button
                  href={
                    process.env.REACT_APP_IMAGE_URL + "/" + params.data.link
                  }
                  variant="text"
                  target="_blank"
                  style={{ fontWeight: "600" }}
                >
                  Download
                </Button>
              </>
            ) : (
              <>
                <img
                  src={process.env.REACT_APP_IMAGE_URL + "/" + params.data.link}
                  alt="profile"
                  width="30"
                  height="30"
                  style={{ paddingTop: "8px" }}
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            {params.data.status !== "CONVERTED TO SALE" ? (
              <>
                <Tooltip title="Edit" arrow placement="top">
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    onClick={() => editHandle(params.data.id)}
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Convert To Sale" arrow placement="top">
                  <IconButton
                    aria-label="add"
                    color="warning"
                    onClick={() => convertQuotation(params.data.id)}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              "CONVERTED"
            )}
          </div>
        );
      },
      maxWidth: 150,
    },
  ]);
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      wrapText: true, // <-- HERE
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}>
            <div className="title-nav">
              <NavLink
                activeclassname="active"
                to="/project/projects"
                className="tab-header"
              >
                Projects
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/quotes"
                className="tab-header"
              >
                Quotes
              </NavLink>
              <NavLink
                activeclassname="active"
                to="/sales"
                className="tab-header"
              >
                Sales Orders
              </NavLink>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/quotes"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Open
                  </NavLink>
                  <NavLink
                    to="/quotation/done"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Done
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="action-buttons">
                  <Tooltip title="Export Excel" placement="left" arrow>
                    <IconButton
                      color="primary"
                      onClick={onBtExport}
                      size="small"
                    >
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "10px" }}></Grid>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sure To Cancelled the Quotation ?"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default QuotesDone;
