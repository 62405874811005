import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import Grid from "@mui/material/Grid";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import { Container, Card, Button, TextField, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { NavLink } from "react-router-dom";
import Header from "../../components/Header/Header";
import { ToastContainer } from "react-toastify";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getProduct,
  getSingleProduct,
  productMarginUpdate,
} from "./Slice/productSlice";
import * as Yup from "yup";

const profitMarginSchema = Yup.object().shape({
  profit_margin: Yup.string().required("Profit Margin is Required"),
});

// ;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Products = () => {

  let d = new Date();
  const params = {
    columnKeys: ['name', 'order', 'cost', 'qty', 'profit', 'margin', 'prodtime'],
    allColumns: false,
    fileName: `Products ${d.toLocaleDateString()}`,
    sheetName:'Products',
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
  
  gridRef.current.api.exportDataAsExcel(params);
  }, []);


  const productList = useSelector((state) => state.product);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProduct());
  }, []);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(productList.products);
  }, [productList.products]);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenEdit = (e) => {
    setOpenEdit(true);
    dispatch(getSingleProduct(e));
  };

  const [columnDefs] = useState([
    // {
    //   field: "",
    //   checkboxSelection: true,
    //   filter: false,
    //   maxWidth: 50,
    // },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      filter: 'agTextColumnFilter',
      cellRenderer: function (params) {
        return (
          <a href="#" target="_blank" rel="noopener">
            {" "}
            {params.value}{" "}
          </a>
        );
      },
    },

    {
      field: "order",
      headerName: "Order No",
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      field: "qty",
      headerName: "Qty",
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: "cost",
      headerName: "Cost",
      sortable: true,
      filter: false,
      filter: 'agTextColumnFilter',
    },
    {
      field: "profit",
      headerName: "Profit",
      sortable: true,
      filter: false,
      filter: 'agTextColumnFilter',
    },
    {
      field: "margin",
      headerName: "Margin",
      sortable: true,
      filter: false,
      
    },
    {
      field: "prodtime",
      headerName: "Prod. Time",
      sortable: true,
      filter: false,
      
    },
    {
      field: "action",
      headerName: "Action",
      sortable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => handleOpenEdit(params.data.id)}
            >
              <CreateIcon />
            </IconButton>
          </div>
        );
      },
      maxWidth: 80,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={12}>
                <div className="nav-bar">
                  <NavLink
                    to="/products"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Products
                  </NavLink>
                  <NavLink
                    to="/materials"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Materials
                  </NavLink>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton 
                  color="primary" 
                  onClick={onBtExport}
                  size="small"
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          Update Profit
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={profitMarginSchema}
            enableReinitialize={true}
            initialValues={{
              id: productList.singleProduct ? productList.singleProduct.id : "",
              order: productList.singleProduct
                ? productList.singleProduct.order_no
                : "",
              product: productList.singleProduct
                ? productList.singleProduct.product_name
                : "",
              cost: productList.singleProduct
                ? productList.singleProduct.material_cost
                : "",
              profit_margin: productList.singleProduct
                ? productList.singleProduct.margin
                : "",
            }}
            onSubmit={async (values) => {
              dispatch(productMarginUpdate(values));
              setOpenEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  type="text"
                  id="order"
                  label="Order"
                  variant="standard"
                  className="w-100 mb-10"
                  onChange={handleChange}
                  value={values.order}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="product"
                  label="Product Name"
                  variant="standard"
                  className="w-100 mb-10"
                  onChange={handleChange}
                  value={values.product}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="cost"
                  label="Cost"
                  variant="standard"
                  className="w-100 mb-10"
                  onChange={handleChange}
                  value={values.cost}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="number"
                  id="profit_margin"
                  name="profit_margin"
                  label="Profit Margin"
                  variant="standard"
                  className="w-100 mb-10"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.profit_margin}
                />
                <ErrorMessage
                  component="div"
                  name="profit_margin"
                  className="error-message"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
    </>
  );
};

export default Products;
