import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { Container, Button, TextField, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Header from "../../components/Header/Header";
import { NavLink, Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import Profile from "../../assets/images/profile.jpg";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch, useSelector } from "react-redux";
// import { getUserDepartmentList } from "../Settings/Department/departmentSlice";
import { getDepartment } from "../Settings/Operations/oprationSlice";
import {
  addUser,
  getUser,
  getSingleUser,
  deleteUser,
  updateUser,
} from "./Slice/userSlice";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const usertype = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Production",
    label: "Production",
  },
  {
    value: "Store",
    label: "Store",
  },
  {
    value: "Supervisor",
    label: "Supervisor",
  },
  {
    value: "Designer",
    label: "Designer",
  },
  {
    value: "Management",
    label: "Management",
  },
];

const userSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  email: Yup.string().required("Email is Required"),
  phone: Yup.string().required("Phone is Required"),
  usertype: Yup.string().required("User Type is Required"),
});

const Users = () => {
  let d = new Date();
  const params = {
    columnKeys: [
      "name",
      "email",
      "phone ",
      "address",
      "usertype",
      "department",
    ],
    allColumns: false,
    fileName: `User ${d.toLocaleDateString()}`,
    sheetName: "User",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const userDepartmentList = useSelector((state) => state.userDepartment);
  const userList = useSelector((state) => state.user);
  const departmentList = useSelector((state) => state.department);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getUser());
  }, []);
  console.log(userList);

  const [department, setDepartment] = React.useState();
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    setDepartment(departmentList.departments);
  }, [departmentList.departments]);

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openDelete, setOpenDelete] = React.useState(false);

  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setDeleteId(e);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleRemove = () => {
    dispatch(deleteUser(deleteId));
    setOpenDelete(false);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(userList.users);
  }, [userList.users]);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = (e) => {
    setOpenEdit(true);
    dispatch(getSingleUser(e));
  };

  const handleCloseEdit = () => setOpenEdit(false);

  const [columnDefs] = useState([
    // {
    //   field: "",
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   filter: false,
    //   maxWidth: 50,
    // },
    {
      field: "profile",
      headerName: "Image",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <img
              src={process.env.REACT_APP_IMAGE_URL + "/" + params.data.profile}
              alt="profile"
              width="30"
              height="30"
              style={{ paddingTop: "8px" }}
            />
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "email",
      headerName: "E-mail Address",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "address",
      headerName: "Address",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "usertype",
      headerName: "User Type",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: "Department",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => handleOpenEdit(params.data.id)}
            >
              <CreateIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => handleClickOpenDelete(params.data.id)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
      maxWidth: 120,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={6}>
                <div className="nav-bar">
                  <NavLink
                    to="/contacts/users"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Users
                  </NavLink>
                  <NavLink
                    to="/contacts/customers"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Customers
                  </NavLink>
                  <NavLink
                    to="/contacts/suppliers"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Suppliers
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <Button variant="text" onClick={handleOpenAdd}>
                    Add User
                  </Button>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton color="primary" onClick={onBtExport} size="small">
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          {/* <Grid item xs={4}>
            <div className="action-buttons">
              <IconButton disabled>
                <DeleteIcon />
              </IconButton>
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
      {/* Add Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseAdd}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdd}
        >
          Add User
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={userSchema}
            initialValues={{
              name: "",
              email: "",
              phone: "",
              address: "",
              usertype: "",
              department: "",
              profile: "",
            }}
            onSubmit={async (values) => {
              dispatch(addUser(values));
              setImageUrl(null);
              handleCloseAdd(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="name"
                  label="Name"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  className="error-message"
                />
                <TextField
                  id="email"
                  label="E-mail Address"
                  variant="standard"
                  type="email"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="error-message"
                />
                <TextField
                  id="phone"
                  label="Phone"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <ErrorMessage
                  component="div"
                  name="phone"
                  className="error-message"
                />
                <TextField
                  id="usertype"
                  name="usertype"
                  select
                  label="User Type"
                  defaultValue="m"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                >
                  {usertype.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <ErrorMessage
                  component="div"
                  name="usertype"
                  className="error-message"
                />
                <TextField
                  id="department"
                  name="department"
                  select
                  label="Department"
                  defaultValue="m"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                >
                  {department.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="address"
                  label="Address"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                />
                <div style={{ display: "flex" }}>
                  <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                      setFieldValue("profile", e.target.files[0]);
                    }}
                  />
                  <label htmlFor="select-image">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <FileUploadOutlinedIcon /> Upload Profile Pic
                    </Button>
                  </label>
                  {imageUrl && selectedImage && (
                    <img
                      src={imageUrl}
                      alt={selectedImage.name}
                      height="40px"
                      style={{ marginLeft: "20px" }}
                    />
                  )}
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Add User
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Add Modal End */}
      {/* Edit Modal Start*/}
      <BootstrapDialog
        onClose={handleCloseEdit}
        aria-labelledby="customized-dialog-title"
        open={openEdit}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseEdit}
        >
          Edit User
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ width: "500px" }}>
          <Formik
            validationSchema={userSchema}
            enableReinitialize={true}
            initialValues={{
              id: userList.singleUser ? userList.singleUser.id : "",
              name: userList.singleUser ? userList.singleUser.name : "",
              email: userList.singleUser ? userList.singleUser.email : "",
              phone: userList.singleUser ? userList.singleUser.phone : "",
              address: userList.singleUser ? userList.singleUser.address : "",
              usertype: userList.singleUser
                ? userList.singleUser.usertype_id
                : "",
              department: userList.singleUser
                ? userList.singleUser.department_id
                : "",
              image: "",
              profile: "",
            }}
            onSubmit={async (values) => {
              dispatch(updateUser(values));
              setOpenEdit(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="name"
                  label="Name"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.name}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  className="error-message"
                />
                <TextField
                  id="email"
                  label="E-mail Address"
                  variant="standard"
                  type="email"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.email}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="error-message"
                />
                <TextField
                  id="phone"
                  label="Phone"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.phone}
                />
                <ErrorMessage
                  component="div"
                  name="phone"
                  className="error-message"
                />
                <TextField
                  id="usertype"
                  name="usertype"
                  select
                  label="User Type"
                  defaultValue="m"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.usertype}
                >
                  {usertype.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <ErrorMessage
                  component="div"
                  name="usertype"
                  className="error-message"
                />
                <TextField
                  id="department"
                  name="department"
                  select
                  label="Department"
                  defaultValue="m"
                  variant="standard"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.department}
                >
                  {department.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="address"
                  label="Address"
                  variant="standard"
                  type="text"
                  className="w-100 mb-15"
                  onChange={handleChange}
                  value={values.address}
                />
                {userList.singleUser.profile != "" ? (
                  <div style={{ display: "flex" }}>
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        "/" +
                        userList.singleUser.profile
                      }
                      alt="image"
                      width="100"
                      height="50"
                    />
                  </div>
                ) : null}
                <div style={{ display: "flex" }}>
                  <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                      setFieldValue("profile", e.target.files[0]);
                    }}
                  />
                  <label htmlFor="select-image">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <FileUploadOutlinedIcon /> Upload Profile Pic
                    </Button>
                  </label>
                  {imageUrl && selectedImage && (
                    <img
                      src={imageUrl}
                      alt={selectedImage.name}
                      height="40px"
                      style={{ marginLeft: "20px" }}
                    />
                  )}
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  autoFocus
                  className="btn-modal"
                >
                  Update User
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </BootstrapDialog>
      {/* Edit Modal End */}
      {/* Delete Modal start */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete the User?"}</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            onClick={handleRemove}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete Modal End */}
    </>
  );
};

export default Users;
