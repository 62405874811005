import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AddCustomer from "./pages/Contacts/AddCustomer";
import EditCustomer from "./pages/Contacts/EditCustomer";
import Customers from "./pages/Contacts/Customers";
import Suppliers from "./pages/Contacts/Suppliers";
import Dashboard from "./pages/Dashboard/Dashboard";
import Products from "./pages/Items/Products";
import Materials from "./pages/Items/Materials";
import Category from "./pages/Settings/Categories/Category";
import Operations from "./pages/Settings/Operations/Operations";
import Settings from "./pages/Settings/Settings";
import TaxRates from "./pages/Settings/TaxRates/TaxRates";
import UnitsOfMeasure from "./pages/Settings/UOM/UnitsOfMeasure";
import AddMaterial from "./pages/Items/AddMaterial";
import EditMaterial from "./pages/Items/EditMaterial";
import Purchase from "./pages/Purchase/Purchase";
import PurchaseDone from "./pages/Purchase/PurchaseDone";
import PurchaseApproved from "./pages/Purchase/PurchaseApproved";
import PurchaseDisapprove from "./pages/Purchase/PurchaseDisapprove";
import ManufacturingOrders from "./pages/Make/ManufacturingOrders";
import ManufacturingOrdersDone from "./pages/Make/ManufacturingOrdersDone";
import SalesOrder from "./pages/Sales/SalesOrder";
import SalesOrderDone from "./pages/Sales/SalesOrderDone";
import AddQuote from "./pages/Quote/AddQuote";
import EditQuote from "./pages/Quote/EditQuote";
import Quotes from "./pages/Quote/Quotes";
import AddSalesOrder from "./pages/Sales/AddSalesOrder";
import EditSalesOrder from "./pages/Sales/EditSalesOrder";
import Login from "./pages/Authentication/Login";
import AddPurchaseOrder from "./pages/Purchase/AddPurchaseOrder";
import AddOutsorcedPurchaseOrder from "./pages/Purchase/AddOutsourcedPurchaseOrder";
import AddManufacturingOrder from "./pages/Make/AddmanufacturingOrder";
import ProductionOrderView from "./pages/Make/ProductionOrderView";
import ProductionOrderPrint from "./pages/Make/ProductionOrderPrint";
import AddMakeOrder from "./pages/Make/AddMakeOrder";
import EditMakeOrder from "./pages/Make/EditMakeOrder";
import Department from "./pages/Settings/Department/Department";
import Users from "./pages/Contacts/Users";
import MakeOrder from "./pages/Make/MakeOrder";
import OrderToManufacturing from "./pages/Make/OrderToManufacturing";
import { useNavigate } from "react-router-dom";
import OutsourcePurchase from "./pages/Purchase/OutsourcingPurchase";
import OutsourcePurchaseDone from "./pages/Purchase/OutsourcePurchaseDone";
import StockOut from "./pages/Make/StockOut";
import AddStockOut from "./pages/Make/AddStockOut";
import EditStockOut from "./pages/Make/EditStockOut";
import PurchaseOrderview from "./pages/Purchase/PurchaseOrderview";
import EditPurchaseOrder from "./pages/Purchase/EditPurchaseOrder";
import PurchaseOutsourceOrderview from "./pages/Purchase/PurchaseOutsourceOrderview";
import EditOutsourcedPurchaseOrder from "./pages/Purchase/EditOutsourcedPurchaseOrder";
import InwardList from "./pages/Purchase/InwardList";
import ManufacturingOrdersCancel from "./pages/Make/ManufacturingOrdersCancel";
import ManufacturingOrdersInprogress from "./pages/Make/ManufacturingOrdersInprogress";
import OrderToManufacturingEdit from "./pages/Make/OrderToManufacturingEdit";
import Billing from "./pages/Billing/Billing";
import BillCancel from "./pages/Billing/BillCancel";
import CreateBill from "./pages/Billing/CreateBill";
import EditBill from "./pages/Billing/EditBill";
import BillPrint from "./pages/Billing/BillPrint";
import ProductionReport from "./pages/Report/ProductionReport";
import MaterialHistory from "./pages/Report/MaterialHistory";
import RawMaterialReport from "./pages/Report/RawMaterialReport";
import Challan from "./pages/Billing/Challan";
import ChallanView from "./pages/Billing/ChallanView";
import QuoteView from "./pages/Quote/QuoteView";
import SalesOrderView from "./pages/Sales/SalesOrderView";
import PackingSlip from "./pages/Make/PackingSlip/PackingSlip";
import PackingPrint from "./pages/Make/PackingSlip/PackingPrint";
import AddProject from "./pages/Project/AddProject";
import MakeQuotation from "./pages/Project/MakeQuotation";
import CreateQuotation from "./pages/Project/CreateQuotation";
import Project from "./pages/Project/Project";
import ViewQuotation from "./pages/Project/ViewQuotation";
import QuotesDone from "./pages/Quote/QuotesDone";
import EditQuotation from "./pages/Project/EditQuotation";
import EditProject from "./pages/Project/EditProject";
import Design from "./pages/Design/Designs";
import GFC from "./pages/Design/GFC";
import OFA from "./pages/Design/OFA";
import Finish from "./pages/Settings/Finish/Finish";
import PrintQuotation from "./pages/Project/PrintQuotation";
import Booklet from "./pages/Booklet/Booklet";
import AddDesign from "./pages/Design/AddDesign";
import EditDesign from "./pages/Design/EditDesign";
import BookletDesign from "./pages/Design/Booklet";
import ProjectAdd from "./pages/Project/ProjectAdd";

function App() {
  const navigate = useNavigate();
  const user = localStorage.getItem("interiah-user");

  useEffect(() => {
    if (!user) {
      setTimeout(() => navigate("/"));
    } else {
      // setTimeout(() => navigate("/dashboard"));
    }
  }, [user]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/sales" element={<SalesOrder />} />
      <Route path="/salesorder/done" element={<SalesOrderDone />} />
      <Route path="/manufacturingorders" element={<ManufacturingOrders />} />
      <Route
        path="/manufacturingorder/done"
        element={<ManufacturingOrdersDone />}
      />
      <Route
        path="/manufacturingorder/cancel"
        element={<ManufacturingOrdersCancel />}
      />
      <Route
        path="/manufacturingorder/inprogress"
        element={<ManufacturingOrdersInprogress />}
      />
      <Route path="/manufacturingorder/stockOut" element={<StockOut />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/settings/uoms" element={<UnitsOfMeasure />} />
      <Route path="/settings/taxRates" element={<TaxRates />} />
      <Route path="/settings/categories" element={<Category />} />
      <Route path="/settings/operations" element={<Operations />} />
      <Route path="/settings/finish" element={<Finish />} />
      <Route path="/settings/department" element={<Department />} />
      <Route path="/contacts/customers" element={<Customers />} />
      <Route path="/contacts/suppliers" element={<Suppliers />} />
      <Route path="/contacts/users" element={<Users />} />
      <Route path="/add-customer" element={<AddCustomer />} />
      <Route path="/edit-customer/:id" element={<EditCustomer />} />
      <Route path="/products" element={<Products />} />
      <Route path="/materials" element={<Materials />} />
      <Route path="/add-material" element={<AddMaterial />} />
      <Route path="/edit-material/:id" element={<EditMaterial />} />

      <Route path="/purchases" element={<Purchase />} />
      <Route path="/purchase/inward-list/:id" element={<InwardList />} />
      <Route path="/purchase-order/:id" element={<PurchaseOrderview />} />
      <Route
        path="/purchase-outsource/:id"
        element={<PurchaseOutsourceOrderview />}
      />
      <Route path="/outsource-purchases" element={<OutsourcePurchase />} />
      <Route path="/purchase/Approved" element={<PurchaseApproved />} />
      <Route path="/purchase/done" element={<PurchaseDone />} />
      <Route path="/purchase/DisApproved" element={<PurchaseDisapprove />} />
      <Route
        path="/outsource-purchase/done"
        element={<OutsourcePurchaseDone />}
      />
      <Route path="/quote" element={<AddQuote />} />
      <Route path="/edit-quote/:id" element={<EditQuotation />} />
      <Route path="/quotes" element={<Quotes />} />
      <Route path="/quotation/done" element={<QuotesDone />} />
      {/* <Route path="/quotation/done" element={<EditQuotation />} /> */}
      <Route path="/salesorder" element={<AddSalesOrder />} />
      <Route path="/sales/edit-sale/:id" element={<EditSalesOrder />} />
      <Route path="/purchaseorder" element={<AddPurchaseOrder />} />
      <Route path="/purchase-edit/:id" element={<EditPurchaseOrder />} />
      <Route
        path="/purchase-outsource-edit/:id"
        element={<EditOutsourcedPurchaseOrder />}
      />
      <Route
        path="/purchase-outsource"
        element={<AddOutsorcedPurchaseOrder />}
      />
      <Route path="/add-makeorder" element={<AddMakeOrder />} />
      <Route path="/edit-makeorder/:id" element={<EditMakeOrder />} />
      <Route path="/makeorder" element={<MakeOrder />} />
      <Route path="/manufacturingorder" element={<AddManufacturingOrder />} />
      <Route
        path="/order-to-manufacturing/:id"
        element={<OrderToManufacturing />}
      />
      <Route
        path="/order-to-manufacturing-edit/:id"
        element={<OrderToManufacturingEdit />}
      />
      <Route
        path="/make/production-view/:id"
        element={<ProductionOrderView />}
      />
      <Route
        path="/make/production-print/:id"
        element={<ProductionOrderPrint />}
      />
      <Route path="/add-stockout/:id" element={<AddStockOut />} />
      <Route path="/edit-stockout/:id" element={<EditStockOut />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/billing" element={<Billing />} />
      <Route path="/BillCancel" element={<BillCancel />} />
      <Route path="/create-bill" element={<CreateBill />} />
      <Route path="/edit-bill/:id" element={<EditBill />} />
      <Route path="/invoice/:id" element={<BillPrint />} />

      <Route path="/report/production-report" element={<ProductionReport />} />
      <Route path="/report/material-history" element={<MaterialHistory />} />
      <Route path="/report/raw-material" element={<RawMaterialReport />} />
      <Route path="/challan" element={<Challan />} />
      <Route path="/challan-view/:id" element={<ChallanView />} />
      <Route path="/make/packing-slip" element={<PackingSlip />} />
      <Route path="/make/packing-print/:id" element={<PackingPrint />} />
      <Route path="/quote/quotation-view/:id" element={<QuoteView />} />
      <Route path="/sales/sales-order-view/:id" element={<SalesOrderView />} />

      <Route path="/add-project/:id" element={<AddProject />} />
      <Route path="/project-add" element={<ProjectAdd />} />
      <Route path="/edit-project/:id" element={<EditProject />} />
      <Route path="/project/make-quotation/:id" element={<MakeQuotation />} />
      <Route
        path="/project/create-quotation/:id"
        element={<CreateQuotation />}
      />
      <Route path="/project/projects" element={<Project />} />
      <Route path="/project/view-quotation/:id" element={<ViewQuotation />} />
      <Route path="/project/print-quotation/:id" element={<PrintQuotation />} />
      {/* Design Section */}
      <Route path="/Design" element={<Design />} />
      <Route path="/OFA" element={<OFA />} />
      <Route path="/GFC" element={<GFC />} />
      <Route path="/booklet/print-booklet/:id" element={<Booklet />} />
      <Route path="/design/add-design/:id" element={<AddDesign />} />
      <Route path="/design/edit-design/:id" element={<EditDesign />} />
      <Route path="/design/booklet" element={<BookletDesign />} />
    </Routes>
  );
}

export default App;
