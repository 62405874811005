import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./pages/Settings/Categories/categorySlice";
import taxReducer from "./pages/Settings/TaxRates/taxrateSlice";
import uomReducer from "./pages/Settings/UOM/uomSlice";
import departmentReducer from "./pages/Settings/Operations/oprationSlice";
import addressReducer from "./pages/CommonSlice/addressSlice";
import customerReducer from "./pages/Contacts/Slice/customerSlice";
import stateReducer from "./pages/CommonSlice/stateSlice";
import supplierReducer from "./pages/Contacts/Slice/supplierSlice";
import materialReducer from "./pages/Items/Slice/materialSlice";
import authenticationReduce from "./pages/Authentication/authenticationSlice";
// import saleSlice from "./pages/Sales/Slice/saleSlice";
import purchaseReducer from "./pages/Purchase/Slice/PurchaseSlice";
import outsourcePurchaseReducer from "./pages/Purchase/Slice/OutsourcePurchaseSlice";
import userDepartmentReducer from "./pages/Settings/Department/departmentSlice";
import userReducer from "./pages/Contacts/Slice/userSlice";
import makeOrderReducer from "./pages/Make/Slice/makeOrderSlice";
import orderToManufactureReducer from "./pages/Make/Slice/orderToManufactureSlice";
import makeApprovedReducer from "./pages/Make/Slice/orderApprovedSlice";
import makeDoneReducer from "./pages/Make/Slice/orderDoneSlice";
import productReducer from "./pages/Items/Slice/productSlice";
import quoteReducer from "./pages/Quote/Slice/quoteSlice";
import inwardReducer from "./pages/Purchase/Slice/InwardSlice";
import orderCancelReducer from "./pages/Make/Slice/orderCancelSlice";
import orderInprogressReducer from "./pages/Make/Slice/orderInprogressSlice";
import billingReducer from "./pages/Billing/Slice/billingSlice";
import SaleSliceViewReducer from "./pages/Sales/Slice/SaleSliceView";
import packingSlipReducer from "./pages/Make/Slice/packingSlice";
import stockOutReducer from "./pages/Make/Slice/stockOutSlice";
import SaleReducer from "./pages/Sales/Slice/saleSlice";
import ProjectReducer from "./pages/Project/Slice/projectSlice";
import productTypeReducer from "./pages/Settings/ProductType/Slice/productTypeSlice";
import projectQuotationReducer from "./pages/Project/Slice/projectQuotationSlice";
import projectDummyReducer from "./pages/Project/Slice/projectDummySlice";
import designReducer from "./pages/Design/Slice/designSlice";
import finishReducer from "./pages/Settings/Finish/finishSlice";
import bookletReducer from "./pages/Booklet/Slice/bookletSlice";
import OFAReducer from "./pages/Design/Slice/OFASlice";
import GFCReducer from "./pages/Design/Slice/GFCSlice";

export const store = configureStore({
  reducer: {
    tax: taxReducer,
    uom: uomReducer,
    category: categoryReducer,
    department: departmentReducer,
    address: addressReducer,
    customer: customerReducer,
    state: stateReducer,
    supplier: supplierReducer,
    material: materialReducer,
    product: productReducer,
    sale: SaleReducer,
    quote: quoteReducer,
    purchase: purchaseReducer,
    outsourcePurchase: outsourcePurchaseReducer,
    userDepartment: userDepartmentReducer,
    makeOrder: makeOrderReducer,
    makeApproved: makeApprovedReducer,
    makeDone: makeDoneReducer,
    orderToManufacture: orderToManufactureReducer,
    orderCancel: orderCancelReducer,
    orderInprogress: orderInprogressReducer,
    user: userReducer,
    inward: inwardReducer,
    authentication: authenticationReduce,
    billing: billingReducer,
    SaleSliceView: SaleSliceViewReducer,
    allProduction: packingSlipReducer,
    stockOut: stockOutReducer,
    project: ProjectReducer,
    productType: productTypeReducer,
    projectQuotation: projectQuotationReducer,
    projectDummy: projectDummyReducer,
    design: designReducer,
    finish: finishReducer,
    booklet: bookletReducer,
    ofa: OFAReducer,
    gfc: GFCReducer
  },
});
