import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { Container, Button, TextField, Tooltip } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import CreateIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Header from "../../components/Header/Header";
import { NavLink, Link, useNavigate } from "react-router-dom";
import {
  getCustomer,
  deleteCustomer,
  getProductionClient,
  returnOrder,
} from "./Slice/customerSlice";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { Formik, Form, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";

const returnSchema = Yup.object().shape({
  production: Yup.string().required("Select Production Id"),
  remark: Yup.string().required("Remark is Required"),
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Customers = () => {
  let d = new Date();
  const params = {
    columnKeys: ["name", "email", "phone", "company"],
    allColumns: false,
    fileName: `Customers ${d.toLocaleDateString()}`,
    sheetName: "Customers",
  };

  const gridRef = useRef();
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(params);
  }, []);

  const customerList = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomer());
  }, []);

  const [rowData, setRowData] = useState();
  useEffect(() => {
    setRowData(customerList.customers);
  }, [customerList.customers]);

  const navigate = useNavigate();
  const editHandle = (e) => {
    navigate("/edit-customer/" + e);
  };

  const createProject = (e) => {
    navigate("/add-project/" + e);
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const [productions, setProduction] = React.useState();
  const [customerName, setCustomerName] = React.useState();

  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
    setDeleteId(e);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleRemove = () => {
    dispatch(deleteCustomer(deleteId));
    setOpenDelete(false);
  };

  const handleReturn = (e, f) => {
    setOpenEdit(true);
    setDeleteId(e);
    setCustomerName(f);
    dispatch(getProductionClient(e));
  };

  useEffect(() => {
    setProduction(customerList.productionList);
  }, [customerList.productionList]);

  const [columnDefs] = useState([
    // {
    //   field: "",
    //   checkboxSelection: true,
    //   filter: false,
    //   maxWidth: 50,
    // },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      filter: "agTextColumnFilter",
      // cellRenderer: function (params) {
      //   return (
      //     <a href="#" rel="noopener" onClick={() => editHandle(params.data.id)}>
      //       {" "}
      //       {params.value}{" "}
      //     </a>
      //   );
      // },
    },
    {
      field: "email",
      headerName: "E-mail Address",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "company",
      headerName: "Company",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "action",
      headerName: "Action",
      filter: false,
      cellRenderer: function (params) {
        return (
          <div>
            {/* <Tooltip title="edit" arrow placement="top">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => editHandle(params.data.id)}
              >
                <CreateIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="delete" arrow placement="top">
              <IconButton
                aria-label="delete"
                color="error"
                onClick={() => handleClickOpenDelete(params.data.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="return" arrow placement="top">
              <IconButton
                aria-label="return"
                color="warning"
                onClick={() => handleReturn(params.data.id, params.data.name)}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Add Project" arrow placement="top">
              <IconButton
                aria-label="add"
                color="success"
                onClick={() => createProject(params.data.id)}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      maxWidth: 180,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      autoHeight: true,
    };
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="grid-tabs-container">
              <Grid item xs={6}>
                <div className="nav-bar">
                  <NavLink
                    to="/contacts/users"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Users
                  </NavLink>
                  <NavLink
                    to="/contacts/customers"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Customers
                  </NavLink>
                  <NavLink
                    to="/contacts/suppliers"
                    activeclassname="active"
                    className="nav-custom"
                  >
                    Suppliers
                  </NavLink>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="text-right">
                  <Link to="/add-customer">
                    <Button variant="text">ADD CUSTOMER</Button>
                  </Link>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className="action-buttons">
              <Tooltip title="Export Excel" placement="left" arrow>
                <IconButton color="primary" onClick={onBtExport} size="small">
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-alpine" style={{ height: 600 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                rowDragManaged={true}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
              ></AgGridReact>
            </div>
          </Grid>
        </Grid>

        {/* Delete Modal start */}
        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete the Customer ?"}
          </DialogTitle>
          <DialogContent style={{ width: "500px" }}>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete}>Cancel</Button>
            <Button
              onClick={handleRemove}
              variant="contained"
              color="error"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Modal End */}
        {/* Edit Modal Start*/}
        <BootstrapDialog
          onClose={handleCloseEdit}
          aria-labelledby="customized-dialog-title"
          open={openEdit}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseEdit}
          >
            Return / Replace Order
          </BootstrapDialogTitle>

          <DialogContent dividers style={{ width: "500px" }}>
            <Formik
              validationSchema={returnSchema}
              enableReinitialize={true}
              initialValues={{
                id: deleteId,
                name: customerName,
                production: "",
                remark: "",
              }}
              onSubmit={async (values) => {
                dispatch(returnOrder(values));
                setOpenEdit(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <TextField
                    id="name"
                    label="Customer Name"
                    variant="standard"
                    className="w-100 mb-15"
                    onChange={handleChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={values.name}
                  />
                  <TextField
                    id="production"
                    name="production"
                    select
                    label="Production"
                    variant="standard"
                    className="w-100 mb-15"
                    onChange={handleChange}
                  >
                    {productions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <ErrorMessage
                    component="div"
                    name="production"
                    className="error-message"
                  />
                  <TextField
                    id="remark"
                    label="Remark"
                    variant="standard"
                    type="text"
                    className="w-100 mb-15"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    component="div"
                    name="remark"
                    className="error-message"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    autoFocus
                    className="btn-modal"
                  >
                    Update
                  </Button>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </BootstrapDialog>
        {/* Edit Modal End */}
        <ToastContainer />
      </Container>
    </>
  );
};

export default Customers;
