import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";
import { toast } from "react-toastify";

// getSupplier
export const getProduct = createAsyncThunk("master/getProduct", async () => {
  try {
    const res = await api.get("admin/master/product");
    return res.data.data;
  } catch (e) {
    console.log(e.res);
  }
});

// get single Product
export const getSingleProduct = createAsyncThunk(
  "master/getSingleProduct",
  async (action) => {
    try {
      const res = await api.get(`admin/master/getProduct/${action}`);
      return res.data.data.data;
    } catch (e) {
      console.log(e.res);
    }
  }
);

//update produnction
export const productMarginUpdate = createAsyncThunk(
  "master/productMarginUpdate",
  async (action) => {
    try {
      const res = await api.post(`admin/master/productMarginUpdate`, action);
      if (res.data.success) {
        let response = {
          success: res.data.success,
          message: res.data.data,
        };
        return response;
      }
      let response = {
        success: res.data.success,
        message: res.data.data.error,
      };
      return response;
    } catch (e) {
      console.log(e.res);
    }
  }
);

const initialState = {
  products: [],
  singleProduct: {},
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state) => {
        state.isAdded = false;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(getProduct.rejected, (state, action) => {})
      .addCase(getSingleProduct.fulfilled, (state, action) => {
        state.singleProduct = action.payload;
      })
      .addCase(productMarginUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          toast.success("Product Profit Margin Updated Successfully");
          state.products = state.products.map((row) =>
            row.id === action.payload.message.id
              ? { ...action.payload.message }
              : row
          );
        } else {
          if (Array.isArray(action.payload.message) === true) {
            const array = action.payload.message;
            array.forEach(function (item, index) {
              toast.error(item);
            });
          } else {
            toast.error(action.payload.message);
          }
        }
      });
  },
});

export default productSlice.reducer;
